<template>
    <div v-if="ready">
        <h2>Cluster-Status</h2>
        <div class="spacer"></div>
        <div class="stats-dashboard">
            <div class="stats box" v-for="env in envs"
                 :key="'box-'+env">
                <h2>{{ env.toUpperCase() }}</h2>
                <div class="spacer"></div>
                <template v-if="undefined !== servers[env].version">
                    <h2 @click="totalsOpen[env] = !totalsOpen[env]">Gesamt</h2>
                    <template v-if="totalsOpen[env]">
                        <table class="margin-top infotable" v-if="undefined !== servers[env].version">
                            <tbody>
                            <tr>
                                <td class="center"><strong>Speichernutzung</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( totals[ env ].rss / 1024 ) )
                                    }}</strong>
                                    MB
                                </td>
                            </tr>
                            <tr>
                                <td class="center"><strong>Speicher %</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( totals[ env ].memory ) )
                                    }}</strong> %
                                </td>
                            </tr>
                            <tr>
                                <td class="center"><strong>CPU %</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( totals[ env ].cpu ) )
                                    }}</strong>
                                    %
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                    <div class="spacer"></div>
                    <h2 @click="serverOpen[env] = !serverOpen[env]">Websocket-Server</h2>
                    <template v-if="serverOpen[env]">
                        <table class="margin-top infotable" v-if="undefined !== servers[env].version">
                            <tbody>
                            <tr>
                                <td class="center"><strong>Server-Version</strong></td>
                                <td :class="'center'+versionStatusClass( 'clusterwss', env )">
                                    <strong>{{ servers[ env ].version }}</strong></td>
                            </tr>
                            <tr>
                                <td class="center"><strong>Uptime</strong></td>
                                <td class="center" v-html="servers[env].uptimeHtml"></td>
                            </tr>
                            <tr>
                                <td class="center"><strong>Speichernutzung</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( servers[ env ].stats.rss / 1024 ) )
                                    }}</strong>
                                    MB
                                </td>
                            </tr>
                            <tr>
                                <td class="center"><strong>Speicher %</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( servers[ env ].stats.usage.memory ) )
                                    }}</strong> %
                                </td>
                            </tr>
                            <tr>
                                <td class="center"><strong>CPU %</strong></td>
                                <td class="center"><strong>{{
                                        numberFormat.format( ( servers[ env ].stats.usage.cpu ) )
                                    }}</strong>
                                    %
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                    <div class="spacer"></div>
                    <Opener id="invoices" :state="open" inline=true
                            caption="Queue-Consumer"
                            @clicked="consumersOpen[env] = !consumersOpen[env]"/>
                    <template v-if="consumersOpen[env]">
                        <table class="margin-top infotable">
                            <tbody>
                            <template v-for="stats in consumers[env]" :key="'tr-'+stats+'-1'">
                                <template v-if="0 < Object.keys( stats ).length">
                                    <tr>
                                        <td class="center"><strong>{{ stats.title.toUpperCase() }}</strong></td>
                                        <td :class="'center'+versionStatusClass( stats.title, env )">Version
                                            <strong>{{ stats.version }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td class="center"><strong>Nutzung</strong></td>
                                        <td class="center">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td><strong>RAM</strong></td>
                                                    <td><strong>RAM %</strong></td>
                                                    <td><strong>CPU %</strong></td>
                                                </tr>
                                                <tr>
                                                    <td class="center"><strong>{{
                                                            numberFormat.format( ( stats.stats.rss / 1024 ) )
                                                        }}</strong> MB
                                                    </td>
                                                    <td class="center"><strong>{{
                                                            numberFormat.format( ( stats.stats.usage.memory ) )
                                                        }}</strong> %
                                                    </td>
                                                    <td class="center"><strong>{{
                                                            numberFormat.format( ( stats.stats.usage.cpu ) )
                                                        }}</strong> %
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            </tbody>
                        </table>
                    </template>
                </template>
                <template v-else>
                    <div style="padding:20px; text-align: center;">
                        <strong>Environment missing</strong>
                    </div>
                </template>
            </div>
            <div class="stats full">
                <h2>Grafische Auswertung</h2>
                <Tabs :tabs="[
                        { id: 'dev', label: 'DEV' },
                        { id: 'beta', label: 'BETA' },
                        { id: 'live', label: 'LIVE' }]"
                      :id="tabId"
                      @clicked="tabId = $event"/>
                <template v-for="env in envs"
                          :key="'graph-'+env">
                    <template v-if="env === tabId">
                        <div class="spacer"></div>
                        <h3>Verbindungen & angemeldete User</h3>
                        <div>

                            <vue3-chart-js :data="dataset[ env ]"
                                           :ref="'chartref-'+env"
                                           :id="'chart-'+env"
                                           :height="100"
                                           type="line"/>

                        </div>
                        <div class="spacer"></div>
                        <h3>Speichernutzung je Service (RSS)</h3>
                        <div>

                            <vue3-chart-js :data="datasetMonService.rss[ env ]"
                                           :ref="'chartref-mon-'+env"
                                           :id="'chartmon--'+env"
                                           :height="100"
                                           type="line"/>

                        </div>
                        <div class="spacer"></div>
                        <h3>CPU-Auslastung je Service (%)</h3>
                        <div>

                            <vue3-chart-js :data="datasetMonService.cpu[ env ]"
                                           :ref="'chartref-mon-'+env"
                                           :id="'chartmon--'+env"
                                           :height="100"
                                           type="line"/>

                        </div>
                        <div class="spacer"></div>
                        <h3>Speichernutzung gesamt</h3>
                        <div>

                            <vue3-chart-js :data="datasetMon.rss[ env ]"
                                           :ref="'chartref-mon-'+env"
                                           :id="'chartmon--'+env"
                                           :height="100"
                                           type="line"/>

                        </div>
                        <div class="spacer"></div>
                        <h3>CPU-Auslastung gesamt (%)</h3>
                        <div>

                            <vue3-chart-js :data="datasetMon.cpu[ env ]"
                                           :ref="'chartref-mon-'+env"
                                           :id="'chartmon--'+env"
                                           :height="100"
                                           type="line"/>

                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
    <Spinner v-else title="Das kann dauern" message="Monitoringwerte werden abgerufen..."/>
</template>

<script>
import Spinner     from "@/components/elements/Spinner";
import Opener      from "@/components/elements/Opener";
import Tabs        from "@/components/elements/Tabs";
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';

export default {
    name      : 'MonitoringCluster',
    components: { Tabs, Opener, Spinner, Vue3ChartJs },
    data()
    {
        return {
            ready            : false,
            envs             : [ 'dev', 'beta', 'live' ],
            tabId            : 'dev',
            readyKey         : false,
            dataset          : {
                dev : {},
                beta: {},
                live: {}
            },
            datasetMon       : {
                memory: {
                    dev : {},
                    beta: {},
                    live: {}
                },
                cpu   : {
                    dev : {},
                    beta: {},
                    live: {}
                },
                rss   : {
                    dev : {},
                    beta: {},
                    live: {}
                }
            },
            datasetMonService: {
                memory: {
                    dev : {},
                    beta: {},
                    live: {}
                },
                cpu   : {
                    dev : {},
                    beta: {},
                    live: {}
                },
                rss   : {
                    dev : {},
                    beta: {},
                    live: {}
                }
            },
            servers          : {
                dev : {},
                beta: {},
                live: {}
            },
            connections      : {
                dev : {},
                beta: {},
                live: {}
            },
            consumers        : {
                dev : {},
                beta: {},
                live: {}
            },
            all              : {
                dev : {},
                beta: {},
                live: {}
            },
            totals           : {
                dev : {},
                beta: {},
                live: {}
            },
            versions         : {
                dev : {},
                beta: {},
                live: {}
            },
            serverOpen       : {
                dev : true,
                beta: true,
                live: true
            },
            totalsOpen       : {
                dev : true,
                beta: true,
                live: true
            },
            consumersOpen    : {
                dev : false,
                beta: false,
                live: false
            }
        }
    },

    created()
    {
        this.numberFormat = new Intl.NumberFormat( 'de-DE' )
    },

    mounted()
    {

        this.refresh()

    },

    beforeDestroy()
    {
        clearInterval( this.timer )
        this.timer = null
    },

    methods: {

        parseFileKey( key )
        {
            let temp = key.split( /\./g ),
                t    = temp[ 1 ],
                k    = temp[ 0 ]

            let temp2 = k.split( /_/g ),
                env,
                service,
                info  = 'monitoring'

            if( 2 === temp2.length )
            {
                env = temp2[ 0 ]
                service = temp2[ 1 ]
            }
            if( 3 === temp2.length )
            {
                env = temp2[ 1 ]
                info = temp2[ 0 ]
                service = temp2[ 2 ]
            }

            return {
                type   : t,
                env    : ( 'test' === env ? 'dev' : env ),
                service: service,
                info   : info,
                key    : key
            }
        },

        uptime( value )
        {

            let minutes = Math.floor( value / 60000 )

            let hours = Math.floor( minutes / 60 )
            minutes = minutes - ( hours * 60 )

            let days = Math.floor( hours / 24 )
            hours = hours - ( days * 24 )

            return '<strong>' + days + '</strong> Tage, <strong>' + hours + '</strong> Stunden, <strong>' + minutes + '</strong> Minuten'

        },

        reduce( set )
        {

            let result = {}

            for( let s in set )
            {

                let data = set[ s ]
                data.timeKey = this.$core.getReformatter().statsTimestamp( 1000 * parseInt( data.tsmp ) )

                if( undefined === result[ data.timeKey ] )
                {
                    result[ data.timeKey ] = { memory: 0, cpu: 0, rss: 0 }
                }

                if( parseFloat( data.usage.cpu ) > result[ data.timeKey ].cpu )
                {
                    result[ data.timeKey ].cpu = parseFloat( data.usage.cpu )
                }

                if( parseFloat( data.usage.memory ) > result[ data.timeKey ].memory )
                {
                    result[ data.timeKey ].memory = parseFloat( data.usage.memory )
                }

                if( parseInt( data.rss ) > result[ data.timeKey ].rss )
                {
                    result[ data.timeKey ].rss = parseInt( data.rss )
                }

            }

            return result

        },

        /*eslint-disable*/
        parseLines( file, all )
        {

            let lines = file.split( /\n/g ),
                temp  = lines.pop()

            if( undefined === all )
            {

                let actual = lines.pop(),
                    setup  = actual.split( /:::/g )

                return {
                    tsmp : setup[ 0 ],
                    pid  : setup[ 1 ],
                    rss  : setup[ 2 ],
                    usage: {
                        memory: setup[ 3 ],
                        cpu   : setup[ 4 ]
                    }
                }

            }
            else
            {
                let set = []
                while( 0 < lines.length )
                {
                    let actual = lines.shift(),
                        setup  = actual.split( /:::/g )

                    set.push( {
                        tsmp : setup[ 0 ],
                        pid  : setup[ 1 ],
                        rss  : setup[ 2 ],
                        usage: {
                            memory: setup[ 3 ],
                            cpu   : setup[ 4 ]
                        }
                    } )
                }
                return this.reduce( set )
            }

        },

        totalize( env, stats, reset )
        {
            let todo = [ 'rss', 'memory', 'cpu' ]

            for( let t in todo )
            {
                let key   = todo[ t ],
                    value = 0
                if( 'rss' === key )
                {
                    value = stats.rss
                }
                else
                {
                    value = stats.usage[ key ]
                }
                if( undefined === this.totals[ env ][ key ] || reset === true )
                {
                    this.totals[ env ][ key ] = 0
                }
                this.totals[ env ][ key ] += parseFloat( value )
            }
        },

        _keySet( data )
        {

            let allKeys = []

            for( let e in this.envs )
            {

                let env = this.envs[ e ]
                for( let t in data[ env ] )
                {
                    for( let k in data[ env ][ t ] )
                    {
                        if( -1 === allKeys.indexOf( k ) )
                        {
                            allKeys.push( k )
                        }
                    }
                }

            }

            return allKeys

        },

        prepareDatasets()
        {

            let values = [ 'memory', 'cpu', 'rss' ]

            for( let e in this.envs )
            {
                let env            = this.envs[ e ],
                    dataset        =
                        {
                            labels  : [],
                            datasets: [],
                        },
                    serviceKeys    = this._keySet( this.all ),
                    connectionKeys = this._keySet( this.connections )

                let connectionStats = {
                    labels     : [],
                    connections: [],
                    sessions   : []
                }
                let monStats = {
                    memory: {},
                    cpu   : {},
                    rss   : {}
                }

                monStats.memory.total = []
                monStats.cpu.total = []
                monStats.rss.total = []

                for( let t in this.connections[ env ] )
                {

                    if( 'null' !== t )
                    {

                        dataset.labels.push( t )
                        let conn = this.connections[ env ][ t ]
                        for( let c in connectionKeys )
                        {

                            connectionStats[ connectionKeys[ c ] ].push( conn[ connectionKeys[ c ] ] )

                        }

                        let mon = this.all[ env ]
                        if( 'object' === typeof ( mon ) )
                        {

                            let totalMemory = 0,
                                totalCpu    = 0,
                                totalRss    = 0

                            for( let s in mon )
                            {

                                let data = this.all[ env ][ s ][ t ]
                                if( undefined === monStats.memory[ s ] )
                                {
                                    monStats.memory[ s ] = []
                                }
                                if( undefined === monStats.cpu[ s ] )
                                {
                                    monStats.cpu[ s ] = []
                                }
                                if( undefined === monStats.rss[ s ] )
                                {
                                    monStats.rss[ s ] = []
                                }

                                if( undefined !== data )
                                {

                                    monStats.memory[ s ].push( data.memory )
                                    monStats.cpu[ s ].push( data.cpu )
                                    monStats.rss[ s ].push( data.rss )

                                    totalMemory += data.memory
                                    totalCpu += data.cpu
                                    totalRss += data.rss

                                }
                                else
                                {
                                    monStats.memory[ s ].push( 0 )
                                    monStats.cpu[ s ].push( 0 )
                                    monStats.rss[ s ].push( 0 )
                                }

                            }

                            monStats.memory.total.push( totalMemory )
                            monStats.cpu.total.push( totalCpu )
                            monStats.rss.total.push( ( totalRss / 1024 ) )

                        }

                    }
                }

                let ds   = [],
                    dc   = [],
                    cR   = 255,
                    cG   = 255,
                    cB   = 255,
                    cIdx = 0

                for( let s in monStats.rss )
                {
                    if( 'total' !== s
                        && 'server' !== s )
                    {

                        let sR, sG, sB
                        switch( cIdx % 6 )
                        {
                            case 0:
                                sR = cR
                                sG = 255 - ( 25 * ( 1 + cIdx ) )
                                sB = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                            case 1:
                                sG = cG
                                sR = 255 - ( 25 * ( 1 + cIdx ) )
                                sB = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                            case 2:
                                sB = cB
                                sG = 255 - ( 25 * ( 1 + cIdx ) )
                                sR = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                            case 3:
                                sR = cR
                                sG = cG
                                sB = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                            case 4:
                                sR = cR
                                sB = cB
                                sG = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                            case 5:
                                sG = cG
                                sB = cB
                                sR = 255 - ( 25 * ( 1 + cIdx ) )
                                break
                        }

                        let backgroundColor = 'rgba( ' + sR + ', ' + sG + ', ' + sB + ', 0.5 )',
                            borderColor     = 'rgba( ' + sR + ', ' + sG + ', ' + sB + ', 1 )'

                        ds.push( {
                            data           : monStats.rss[ s ],
                            fill           : true,
                            label          : s,
                            backgroundColor: backgroundColor,
                            borderColor    : borderColor,
                            borderWidth    : 1,
                            tension        : 0.2,
                            opacity        : 0.5
                        } )

                        dc.push( {
                            data           : monStats.cpu[ s ],
                            fill           : true,
                            label          : s,
                            backgroundColor: backgroundColor,
                            borderColor    : borderColor,
                            borderWidth    : 1,
                            tension        : 0.2,
                            opacity        : 0.5
                        } )

                        cIdx++
                        switch( cIdx % 3 )
                        {
                            case 0:
                                cR -= 55
                                break
                            case 1:
                                cG -= 55
                                break
                            case 2:
                                cB -= 55
                                break
                        }

                    }
                }
                this.datasetMonService.rss[ env ] = {
                    labels  : dataset.labels,
                    datasets: ds
                }
                this.datasetMonService.cpu[ env ] = {
                    labels  : dataset.labels,
                    datasets: dc
                }
                this.datasetMon.memory[ env ] = {
                    labels  : dataset.labels,
                    datasets: [
                        {
                            data           : monStats.memory.total,
                            fill           : true,
                            label          : 'Memory %',
                            backgroundColor: "rgba( 255, 192, 147, 0.5 )",
                            borderColor    : 'orange',
                            borderWidth    : 1,
                            tension        : 0.2,
                            opacity        : 0.5
                        }
                    ]
                }

                this.datasetMon.rss[ env ] = {
                    labels  : dataset.labels,
                    datasets: [
                        {
                            data           : monStats.rss.total,
                            fill           : true,
                            label          : 'Memory (RSS in MB)',
                            backgroundColor: "rgba( 255, 200, 200, 0.5 )",
                            borderColor    : 'red',
                            borderWidth    : 1,
                            tension        : 0.2,
                            opacity        : 0.5
                        }
                    ]
                }

                this.datasetMon.cpu[ env ] = {
                    labels  : dataset.labels,
                    datasets: [
                        {
                            data           : monStats.cpu.total,
                            fill           : true,
                            label          : 'CPU usage (%)',
                            backgroundColor: "rgba( 200, 255, 200, 0.5 )",
                            borderColor    : 'green',
                            borderWidth    : 1,
                            tension        : 0.2,
                            opacity        : 0.5
                        }
                    ]
                }

                dataset.datasets.push( {
                    data           : connectionStats.sessions,
                    fill           : true,
                    label          : 'Sessions',
                    backgroundColor: "rgba( 255, 255, 200, 0.5 )",
                    borderColor    : 'yellow',
                    borderWidth    : 1,
                    tension        : 0.2,
                    opacity        : 0.5
                } )

                dataset.datasets.push( {
                    data           : connectionStats.connections,
                    fill           : true,
                    label          : 'Connections',
                    backgroundColor: "rgba( 200, 255, 200, 0.5 )",
                    borderColor    : 'green',
                    borderWidth    : 1,
                    tension        : 0.2,
                    opacity        : 0.2
                } )

                this.dataset[ env ] = dataset

            }
        },

        parseConnections( file )
        {

            let lines  = file.split( /\n/g ),
                conn   = [],
                result = {}

            for( let l in lines )
            {

                let line   = lines[ l ],
                    values = line.split( /:::/g ),
                    set    = {
                        timeKey    : false,
                        connections: 0,
                        sessions   : 0
                    }

                for( let v in values )
                {
                    switch( parseInt( v ) )
                    {
                        case 0:
                            set.timeKey = this.$core.getReformatter().statsTimestamp( values[ v ] )
                            break
                        case 1:
                            set.connections = parseInt( values[ v ] )
                            break
                        case 2:
                            set.sessions = parseInt( values[ v ] )
                            break
                    }
                }

                conn.push( set )

            }

            for( let c in conn )
            {

                let data = conn[ c ]
                if( undefined === result[ data.timeKey ] )
                {
                    result[ data.timeKey ] = { connections: 0, sessions: 0 }
                }

                if( data.connections > result[ data.timeKey ].connections )
                {
                    result[ data.timeKey ].connections = data.connections
                }

                if( data.sessions > result[ data.timeKey ].sessions )
                {
                    result[ data.timeKey ].sessions = data.sessions
                }

            }

            return result

        },

        parseFiles( files, reset )
        {

            console.log( files )

            for( let f in files )
            {

                let file = files[ f ],
                    key  = this.parseFileKey( file.key )

                this.consumers[ key.env ][ key.service ] = this.consumers[ key.env ][ key.service ] || {}

                switch( key.type )
                {
                    case 'mon':
                        switch( key.service )
                        {
                            case 'clusterwss':
                                this.servers[ key.env ].stats = this.parseLines( file.file )
                                this.all[ key.env ].server = this.parseLines( file.file, true )
                                this.totalize( key.env, this.servers[ key.env ].stats, reset )
                                break
                            default:
                                if( undefined === this.consumers[ key.env ][ key.service ] || reset === true )
                                {
                                    this.consumers[ key.env ][ key.service ] = {}
                                }
                                this.consumers[ key.env ][ key.service ].stats = this.parseLines( file.file )
                                this.all[ key.env ][ key.service ] = this.parseLines( file.file, true )
                                this.consumers[ key.env ][ key.service ].title = key.service
                                this.totalize( key.env, this.consumers[ key.env ][ key.service ].stats, reset )
                                break
                        }
                        break
                    case 'info':
                        switch( key.info )
                        {
                            case 'version':
                                switch( key.service )
                                {
                                    case 'clusterwss':
                                        this.servers[ key.env ].version = file.file
                                        this.versions[ key.env ][ 'clusterwss' ] = this.servers[ key.env ].version
                                        this.servers[ key.env ].uptimeHtml = this.uptime( Date.now() - ( new Date( file.mtime ).getTime() ) )
                                        break
                                    default:
                                        this.consumers[ key.env ][ key.service ].version = file.file
                                        this.versions[ key.env ][ key.service ] = this.consumers[ key.env ][ key.service ].version
                                        break
                                }
                                break
                        }
                        break
                    case 'serve':
                        this.connections[ key.env ] = this.parseConnections( file.file )
                        break
                }
            }

            this.prepareDatasets()
            this.ready = true

        },

        versionStatusClass( service, env )
        {

            switch( env )
            {
                case 'dev':
                    return ''
                case 'beta':
                    return this.versions.dev[ service ] !== this.versions.beta[ service ] ? ' error' : ' good'
                case 'live':
                    return this.versions.beta[ service ] !== this.versions.live[ service ] ? ' error' : ' good'
            }
            return ''

        },

        refresh()
        {

            let message = {
                method: 'admin.stats_getClusterMonitoring'
            }

            this.$core
                .getClient()
                .request( message )
                .then( response =>
                {
                    this.parseFiles( response.result )
                    this.readyKey = '--key-' + Date.now()
                } )

        }

    }
}
</script>
<style scoped>
h3, h4 {
    user-select: none;
}
</style>